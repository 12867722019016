import { 
  Panel,
  Grid,
  ButtonLink, 
} from '../../styles/global.elements'
import Accordion, { AccordionListItem } from '../accordion'
import ProcedureItem from './procedureItem'

const ProceduresSection = () => {
  return (
    <Panel mt="2rem">
      <h3>Trámites</h3>
      <Grid flexWrap="wrap" justify="space-between" align="stretch" breakpoint="xl">
        <ProcedureItem
          mt="1rem"
          title="Solicitud de alzamientos"
          buttonText="Solicitar"
          link="/requests/uprisingRequest"
          image="/images/procedures/uprisingRequest.png" 
        >
          En esta sección podrá ingresar las escrituras para requerir <b>alzamientos y/o cancelaciones</b> en
          los registros de <b>Hipotecas</b> y <b>Prohibiciones</b>.
        </ProcedureItem>
        <ProcedureItem
          title="Solicitud de inscripciones"
          buttonText="Solicitar ingreso"
          link="/requests/deedRequest"
          image="/images/procedures/deedRequest.png"
          mt="1rem"
        >
          En esta sección podrá ingresar <b>escrituras</b> para requerir su inscripción
          en los registros de <b>Propiedad</b>, <b>Comercio</b>, <b>Aguas</b>, <b>Minas</b>, <b>Hipotecas</b> y <b>Prohibiciones</b>,
          indicando la notaría y el código de verificación.
        </ProcedureItem>
        <ProcedureItem
          title="Solicitud de copias o certificados"
          buttonText="Solicitar"
          link="/requests/copyCertificateRequest"
          image="/images/procedures/copyCertificateRequest.png"
          mt="1rem"
        >
          En esta sección se pueden pedir copias o certificados de inscripciones.
          <Accordion title="Documentos que pueden ser solicitados">
            <ul>
              <AccordionListItem>
                Copia
              </AccordionListItem>
              <AccordionListItem>
                Copia con vigencia
              </AccordionListItem>
              <AccordionListItem>
                Certificado de hipoteca y gravámenes
              </AccordionListItem>
              <AccordionListItem>
                Vigencia de sociedad
              </AccordionListItem>
              <AccordionListItem>
                Vigencia de sociedad con representante legal
              </AccordionListItem>
              <AccordionListItem>
                Titulación
              </AccordionListItem>
              <AccordionListItem>
                <ButtonLink href="/requests/copyCertificateRequest">
                  Solicitar
                </ButtonLink>
              </AccordionListItem>
            </ul>
          </Accordion>
        </ProcedureItem>
      </Grid>
      <Grid flexWrap="wrap" justify="space-between" align="stretch" breakpoint="xl">
        <ProcedureItem
          title="Solicitud de copias de planos y documentos"
          buttonText="Solicitar"
          link="/requests/blueprintsDocsRequest"
          image="/images/procedures/blueprintsDocsRequest.png" 
          mt="1rem"
        >
          En esta sección se pueden pedir copias de planos o documentos que
          sean del registro del Conservador de Bienes Raíces de Puerto Varas.
          <Accordion title="Documentos que pueden ser solicitados">
            <ul>
              <AccordionListItem>
                Copia de plano
              </AccordionListItem>
              <AccordionListItem>
                Copia de documentos
              </AccordionListItem>
              <AccordionListItem>
                <ButtonLink href="/requests/blueprintsDocsRequest">
                  Solicitar
                </ButtonLink>
              </AccordionListItem>
            </ul>
          </Accordion>
        </ProcedureItem>
        <ProcedureItem
          title="Consulta de índices"
          buttonText="Consultar"
          link="/queries/indexQuery"
          image="/images/procedures/indexQuery.png" 
          mt="1rem"
        >
          Desde esta sección puede consultar y
          ver los índices que desee en formato pdf.,
          buscando a través de razón social o apellido.
          También es posible solicitar el certificado
          que desee de la misma forma.
        </ProcedureItem>
        <ProcedureItem
          mt="1rem"
          title="Solicitud de copias de escrituras públicas"
          buttonText="Solicitar"
          link="/requests/protocolsRequest"
          image="/images/procedures/protocolsRequest.png" 
        >
          En esta sección se pueden solicitar copias de escrituras Públicas
          de las notarías de Puerto Varas y Llanquihue.
          <Accordion title="Documentos que pueden ser solicitados">
            <ul>
              <AccordionListItem>
                Copia de escritura pública con vigencia
              </AccordionListItem>
              <AccordionListItem>
                Copia de escritura pública sin vigencia
              </AccordionListItem>
              <AccordionListItem>
                <ButtonLink href="/requests/protocolsRequest">
                  Solicitar
                </ButtonLink>
              </AccordionListItem>
            </ul>
          </Accordion>
        </ProcedureItem>
      </Grid>
      <Grid flexWrap="wrap" justify="space-between" align="stretch" breakpoint="xl">        
        <ProcedureItem
          title="Búsqueda de carátula"
          buttonText="Buscar"
          link="/queries/coverSearch"
          image="/images/procedures/coverSearch.png" 
          mt="1rem"
        >
          En esta sección podrá encontrar todas las
          carátulas solicitadas por usted.
        </ProcedureItem>
        <ProcedureItem
          title="Registros escaneados"
          buttonText="Ver registros"
          link="/queries/scanRegistry"
          image="/images/procedures/scanRegistry.png" 
          mt="1rem"
        >
          Se pueden revisar los registros del conservador
          de bienes raices a través de esta sección
          con formato pdf, con el fojas, número y año.
        </ProcedureItem>
      </Grid>
    </Panel>
  )
}
 
export default ProceduresSection